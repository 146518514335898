var site = site || {};
var generic = generic || {};
var Mustache = Mustache || {};
var IScroll = IScroll || {};
var popupScroll = popupScroll || {};

site.account = site.account || {};
site.signin = site.signin || {};
site.userInfoCookie = site.userInfoCookie || {};
site.userInfoCookie.getValue =
  site.userInfoCookie.getValue ||
  function () {
    return '';
  };

(function ($) {
  Drupal.behaviors.signIn = {
    attach: function (context) {
      var signedIn = site.userInfoCookie.getValue('signed_in');
      var forceReturn = false;
      var returnURL = null;

      if (signedIn === '0') {
        signedIn = false;
      }

      // Determine if user has signed in before by looking at the persistent
      // user cookie.
      var persistentCookie = Drupal.ELB.getJSONCookie('persistent_user_cookie');
      // @TODO: get email address too so we can put it in the input field
      var fullName = persistentCookie.first_name;
      var firstTime = persistentCookie.first_time;
      var userDetected = !!fullName;
      var $overlayTemplate = $('#signin-overlay-template', context);
      var overlayContent = $overlayTemplate.html();
      var $triggerButton = $(
        '.page-utilities__account-button, .js-launch-account, .field-mobile-menu .sign-in---my-account',
        context
      );
      // Determine the state of the overlay to show:
      var signInOverlayState = $.cookie('signInOverlayState', { path: '/' });

      // Delete the cookie immediately (apparently these are both needed?):
      $.cookie('signInOverlayState', null, { path: '/' });
      $.cookie('signInOverlayState', null);

      var isMobile = !$('body').hasClass('device-pc');
      var $signInPage = $('.sign-in-page', context);
      var isSignInPage = $signInPage.length;
      var colorboxSettings = {
        html: overlayContent,
        className: 'signin-overlay-wrapper',
        width: '100%',
        maxWidth: '1022px',
        fixed: true
      };

      if (isMobile) {
        colorboxSettings.top = '0px';
        colorboxSettings.height = '1000px';
      }

      // User greeting and login/logout link below Account button
      if (userDetected && !isMobile) {
        if (signedIn) {
          $('.user-logged-in').show();
          $('.user-logged-out').hide();
        } else {
          $('.user-logged-out').show();
          $('.user-logged-in').hide();
        }
      }

      function _launchOverlay(forceReturn, returnURL) {
        $.colorbox(colorboxSettings);

        var $overlay = $('.signin-overlay-wrapper .sign-in-component');

        // Redirect back to the current page
        // var returnURL = '?RETURN_URL=' + window.location.pathname;
        // Generally only registration sends you back to your last page, but
        // there are some cases where sign in can (i.e., "Save to Profile" in the
        // Foundation Finder)
        if (forceReturn) {
          $('form', $overlay).each(function () {
            if (!$('input[name=RETURN_URL]', this).length) {
              $(this).append('<input type="hidden" name="RETURN_URL" value="" />');
            }
          });
        }
        returnURL = returnURL || window.location.pathname + window.location.search;

        $('input[name=RETURN_URL]', $overlay).val(returnURL);
        $('.sign-in-component__form--registration input[name=RETURN_URL]', $overlay).val(
          '/account/index.tmpl'
        );
        _initForm($overlay);

        // Init selectboxes for desktop:
        if (!isMobile) {
          $('.selectbox', $overlay).selectBox();
          // Apply global js text input behavior:
          Drupal.behaviors.formTextInputs.attach($('.signin-overlay-wrapper'));
        }
      }

      function _initForm($wrapper) {
        $('.sign-in-component', $signInPage).show();
        var $registerForm = $('.sign-in-component__form--registration', $wrapper);
        var $signInForm = $('.sign-in-component__form--sign-in', $wrapper);
        var $showPass = $('input[name=SHOW_PASSWORD]', $wrapper);
        var $pass = $('input[type=password]', $registerForm);
        var $regEmailAddress = $('input[name=PC_EMAIL_ADDRESS]', $registerForm);
        var $signInEmailAddress = $('input[name=EMAIL_ADDRESS]', $signInForm);
        var $error_messages_list = $('ul.error_messages').find('li');
        var $regToken = $('input[name=_TOKEN]', $registerForm);

        // Set the appropriate class on the outer container to tell css what to
        // display. By default we show the registration
        // form, but if the overlay state cookie indicates we just registered or
        // signed in, we show the relevant confirmation screen instead. Finally,
        // if the user's ever logged in on this machine we display the sign in
        // form by default.

        // First off, if there's an error in the form, and we're trying to show
        // a confirmation page, go back a step:
        if (isSignInPage && $('input.error, select.error', $wrapper).length) {
          if (signInOverlayState === 'register-confirm') {
            signInOverlayState = 'register';
          } else if (signInOverlayState === 'signin-confirm') {
            signInOverlayState = 'signin';
          }
        }

        if (isSignInPage && $('#account_lockout\\.\\.').is(':visible')) {
          if (signInOverlayState === 'signin-confirm') {
            signInOverlayState = 'signin';
          }
        }

        if (isSignInPage && $('#account_exists\\.\\.').is(':visible')) {
          $signInEmailAddress.val($regEmailAddress.val());
          $signInEmailAddress.focus();
          signInOverlayState = 'signin';
        }

        // Toggle the class:
        if (signInOverlayState === 'register') {
          $wrapper.addClass('registration');
        } else if (signInOverlayState === 'register-confirm') {
          $wrapper.addClass('registration-confirmation');
        } else if (signInOverlayState === 'signin-confirm') {
          // $wrapper.addClass('sign-in-confirmation');
        } else if (signInOverlayState === null || signInOverlayState === 'signin' || userDetected) {
          $wrapper.addClass('sign-in');
        }

        // if user has registered before then show sign in form
        if (firstTime === 0 && signInOverlayState !== 'register-confirm' && userDetected) {
          $wrapper.addClass('sign-in');
        }

        if (
          (signInOverlayState === 'register' &&
            $('form[name="registration"] input.error').length > 0) ||
          $('form[name="registration"] label.error').length > 0
        ) {
          $wrapper.removeClass('sign-in');
        }

        // Remove any stray error classes that may have ended up on the hidden forms:
        $('form:hidden', $wrapper).find('input.error, select.error').removeClass('error');

        // Preprocess the form:

        $pass.each(function () {
          $(this).after(
            '<div class="visible-pass-wrapper"><input class="visible-pass form-text" type="text" style="display: none;" /></div>'
          );
          if (!isMobile && Drupal.behaviors.formTextInputs !== undefined) {
            Drupal.behaviors.formTextInputs.attach($('.visible-pass-wrapper'));
          }
        });
        var $visiblePass = $('.visible-pass', $wrapper);

        // Add the user's full name to the sign in confirmation screen header:
        if (fullName) {
          var $signInConfirmHeader = $(
            '.sign-in-component__confirm--sign-in .sign-in-component__header',
            $wrapper
          );

          $signInConfirmHeader.text($signInConfirmHeader.text().replace('full_name', fullName));
        }

        if ($('.sign-in-component__fpw-link', $wrapper).length > 0) {
          site.signin.forgotPassword({
            resetPassword: true,
            emailNode: $('input#sign-in-component__EMAIL_ADDRESS', $wrapper),
            errorListNode: $('.signin-block__lost-pass-text', $wrapper),
            forgotPasswordLink: $('#forgot-password', $wrapper),
            forgotPasswordNote: $('p#forgot_pw_note', $wrapper)
          });
        }

        // Bind events:

        $showPass.on('change', function () {
          var show = $(this).is(':checked');

          $visiblePass.add($pass).toggle();
          if (show) {
            $('.visible-pass', $wrapper).each(function () {
              $(this).val($(this).parent().prev().val()).trigger('blur');
            });
          } else {
            $pass.each(function () {
              $(this).val($(this).next().children().first().val()).trigger('blur');
            });
          }
        });

        // Prevent the sms form from submitting on the register-confirm overlay when the mobile number is blank
        if (signInOverlayState === 'register-confirm') {
          $('.sign-in-component__confirm-options, input[type=submit]').on('click', function () {
            var mobileNumber = $('#sign-in-component__SMSPROMO_MOBILE_NUMBER');

            if ($(mobileNumber).attr('value') === '') {
              $('input[type=hidden], [name=_SECONDARY_SUBMIT], [value=sms]').remove();
            }
          });
        }

        if ($.cookie('csrftoken')) {
          $regToken.val($.cookie('csrftoken'));
        }

        $signInForm.add($registerForm).on('submit', function () {
          // Set the password field to what's in the visible password field if
          // show password is checked
          var showPass = $showPass.is(':checked');

          if (showPass) {
            $pass.each(function () {
              $(this).val($(this).next().children().first().val());
            });
          }

          // Set a cookie so we remember which form was submitted so we can
          // launch the relevant confirmation overlay on the next page load
          var cookieVal = $(this).hasClass('sign-in-component__form--sign-in')
            ? 'signin-confirm'
            : 'register-confirm';

          $.cookie('signInOverlayState', cookieVal, { path: '/' });
        });

        if (document.location.hostname.match(/\.ca/)) {
          // by default box should be unchecked for Canada
          $('input[name="PC_EMAIL_PROMOTIONS"]').removeProp('checked');
        }

        $('.signin-overlay__toggle-form a', $wrapper).on('click.signIn', function (event) {
          event.preventDefault();
          $error_messages_list;
          if ($error_messages_list) {
            $error_messages_list.hide();
          }
          $wrapper.toggleClass('sign-in');

          if (document.location.hostname.match(/\.ca/)) {
            // by default box should be unchecked for Canada
            $('input[name="PC_EMAIL_PROMOTIONS"]').removeProp('checked');
          }
        });

        $('.sign-in-component__close', $wrapper).on('click.signIn', function (event) {
          event.preventDefault();
          $.colorbox.close();
          if (signInOverlayState === 'register-confirm' && signedIn) {
            $('.my-feed-drawer .drawer-formatter__trigger').trigger('mouseover');
          }
        });
      } // /End initForm()

      $triggerButton.on('click.signIn', function (event) {
        event.preventDefault();

        var forceReturn = $(this).hasClass('js-launch-account--return');
        var returnURL = forceReturn ? $(this).attr('data-return-url') : null;

        // If already signed in, this button works as a link to the account
        // landing.
        if (signedIn) {
          window.location = '/account/index.tmpl';
        } else if ($('.sign-in-component', context).length) {
          $('.sign-in-component', context).find('.form-text:visible').first().focus();
        } else {
          _launchOverlay(forceReturn, returnURL);
          if (site.facebook) {
            site.facebook.init();
          }
          if (generic.env.isIOS4) {
            popupScroll.destroy();
            popupScroll = null;
            popupScroll = new IScroll('#colorbox', { mouseWheel: true });
            setTimeout(function () {
              popupScroll.refresh();
            }, 500);
          }
        }
        if (site.facebook) {
          site.facebook.init();
        }
      });

      $('.bfeed-create-account').on('click.signIn', function (event) {
        event.preventDefault();
        var forceReturn = $(this).hasClass('js-launch-account--return');
        var returnURL = forceReturn ? $(this).attr('data-return-url') : null;

        // If already signed in, this button works as a link to the account
        // landing.
        if (signedIn) {
          window.location = '/account/index.tmpl';
        } else if ($('.sign-in-component', context).length) {
          $('.sign-in-component', context).find('.form-text:visible').first().focus();
        } else {
          _launchOverlay(forceReturn, returnURL);
          site.facebook.init();
          if (generic.env.isIOS4) {
            popupScroll.destroy();
            popupScroll = null;
            popupScroll = new IScroll('#colorbox', { mouseWheel: true });
            setTimeout(function () {
              popupScroll.refresh();
            }, 500);
          }
        }
      });

      $('.bfeed-sign-in').on('click.signIn', function (event) {
        event.preventDefault();
        var forceReturn = $(this).hasClass('js-launch-account--return');
        var returnURL = forceReturn ? $(this).attr('data-return-url') : null;

        // If already signed in, this button works as a link to the account
        // landing.
        if (signedIn) {
          window.location = '/account/index.tmpl';
        } else if ($('.sign-in-component', context).length) {
          $('.sign-in-component', context).find('.form-text:visible').first().focus();
        } else {
          _launchOverlay(forceReturn, returnURL);
          if (generic.env.isIOS4) {
            popupScroll.destroy();
            popupScroll = null;
            popupScroll = new IScroll('#colorbox', { mouseWheel: true });
            setTimeout(function () {
              popupScroll.refresh();
            }, 500);
          }
          $('.signin-overlay__toggle-form.signin-overlay__toggle-form--have-acount a').trigger(
            'click'
          );
        }
      });

      // Automatically launch the overlay if the cookie is set and we're not on
      // the dedicated sign in page.
      if (!isSignInPage) {
        // Disabling the sign in confirmation functionality since it was removed from the spec.
        // if ((signInOverlayState == 'register-confirm' || signInOverlayState == 'signin-confirm') && signedIn) {
        if (signInOverlayState === 'register-confirm' && signedIn) {
          _launchOverlay(forceReturn, returnURL);
        }
      }

      // Run initForm directly on the context. This will only really be useful
      // for the sign in page, where the form is already embedded.
      _initForm($('.sign-in-component', context));

      // Ensure this script doesn't break site if perlgem isn't running:
      if (typeof site != 'undefined' && typeof site.userInfoCookie != 'undefined' && fullName) {
        // enabling to use full_name placeholder in CMS
        var $template = $('.user-login-state');
        fullName = fullName.replace(/[^a-zA-Z0-9]/g, ' ');
        var rendered = Mustache.render($template.html(), { first_name: fullName });
        $template.html(rendered);
        // if user clicks on 'Sign out' link reset recognized user related cookies
        $('.sign-out-link').each(function () {
          var $signOutLink = $(this);
          var signOutURL = $signOutLink.attr('href');

          $signOutLink.attr('href', signOutURL).on('click', function () {
            var domain =
              '.' +
              window.location.hostname.replace(/^.*(esteelauder\.)/, function (m, $1) {
                return $1;
              });
            var cookieObj = $.parseJSON(
              $.cookie('persistent_user_cookie', {
                path: '/',
                domain: domain
              })
            );

            cookieObj.full_name = null;
            $.cookie('persistent_user_cookie', JSON.stringify(cookieObj), {
              path: '/',
              domain: domain
            });
            $.cookie('persistent_user_last_purchase', null, { path: '/' });
            $.cookie('expandMyFeedTray', 0, { path: '/' });
          });
        });
      }
    }
  };
  $(document).on('change', '.js-signin-component__cpf', function (event) {
    event.preventDefault();
    $('.sign-in-component .js-fed-doc-number').val($(this).val());
  });
})(jQuery);
